import { ROUTES } from '@/routes/Routes';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './hooks/use-auth';
import Loader from './layouts/Loader';

const Authentication = React.lazy(() => import('./layouts/Authentication'));
const Board = React.lazy(() => import('./layouts/Board'));
const Error = React.lazy(() => import('./layouts/pages/Error'));

function LoadUser() {
  const auth = useAuth();

  if (auth.user === undefined) return <Loader />;

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Authentication />
            </Suspense>
          }
        />
        {ROUTES.map((e, i) => (
          <Route
            key={i}
            path={e.path}
            element={
              <Suspense fallback={<Loader />}>
                <Board title={e.title}>{e.component ? <e.component /> : <Error />}</Board>
              </Suspense>
            }
          />
        ))}
        {/* already redirect to login, authentication component perform redirection to app if already connected */}
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </Router>
  );
}

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <LoadUser />
      </AuthProvider>
    </div>
  );
}

export default App;
