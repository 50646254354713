import React from 'react';
import {
  RiChatNewFill,
  RiCommunityFill,
  RiContactsBook2Fill,
  RiContactsFill,
  RiFlashlightFill,
  RiGroupFill,
  RiHome2Fill,
  RiLineChartFill,
  RiSendPlaneFill,
  RiTeamFill
} from 'react-icons/ri';

export const getMenuItem = (names: string) => ROUTES.filter((item) => names.includes(item.name))[0];
export const getMenuItems = (names: Array<string>) => ROUTES.filter((item) => names.includes(item.name));

export const ROUTES = [
  {
    name: 'flash',
    title: 'menu.flash',
    path: '/flash',
    icon: <RiFlashlightFill />
  },
  {
    name: 'home',
    title: 'menu.home',
    path: '/home',
    icon: <RiHome2Fill />,
    component: React.lazy(() => import('@/layouts/pages/Home'))
  },
  {
    name: 'users',
    title: 'menu.users',
    path: '/users',
    icon: <RiContactsFill />,
    component: React.lazy(() => import('@/layouts/pages/Users'))
  },
  {
    name: 'friends',
    title: 'menu.friends',
    path: '/friends',
    icon: <RiGroupFill />
  },
  {
    name: 'family',
    title: 'menu.family',
    path: '/family',
    icon: <RiTeamFill />
  },
  {
    name: 'community',
    title: 'menu.community',
    path: '/community',
    icon: <RiCommunityFill />
  },
  {
    name: 'push',
    title: 'menu.push_center',
    path: '/push',
    icon: <RiChatNewFill />
  },
  {
    name: 'sender',
    title: 'menu.sender',
    path: '/sender',
    icon: <RiSendPlaneFill />
  },
  {
    name: 'segment',
    title: 'menu.segment',
    path: '/segment',
    icon: <RiContactsBook2Fill />
  },
  {
    name: 'analytics',
    title: 'menu.analytics',
    path: '/analytics',
    icon: <RiLineChartFill />
  },
  {
    name: 'download',
    title: 'submenu.dl_app',
    path: '/showapp',
    bold: true,
    external: true
  },
  {
    name: 'documentation',
    title: 'submenu.documentation',
    path: '/documentation'
  },
  {
    name: 'guides',
    title: 'submenu.guides',
    path: '/guides'
  },
  {
    name: 'faq',
    title: 'submenu.faq',
    path: '/faq'
  }
];
