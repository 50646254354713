import { Box, Flex, Spinner } from '@chakra-ui/react';

function Loader() {
  return (
    <Box bg="gray.50">
      <Flex minH="100vh" justify="center" align="center" direction="column">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Flex>
    </Box>
  );
}

export default Loader;
