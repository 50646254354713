import { auth } from '@/firebase/config';
import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { createGenericContext } from '../helper/createGenericContext';

interface AuthProviderInterface {
  user: User | null | undefined;
  login(email: string, password: string): Promise<User>;
  logout(): Promise<void>;
}

// Generate generic context
const [useAuthContext, AuthContextProvider] = createGenericContext<AuthProviderInterface>();

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useAuthContext();
};

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function AuthProvider({ children }: { children: JSX.Element }) {
  const useAuth = useAuthProvider();
  return <AuthContextProvider value={useAuth}>{children}</AuthContextProvider>;
}

// Provider hook that creates auth object and handles state
function useAuthProvider(): AuthProviderInterface {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  console.log('user', user);

  const login = async (email: string, password: string) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    setUser(response.user);
    return response.user;
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    // Cleanup subscription on unmount
    return () => {
      console.log('Unmounting');
      unsubscribe();
    };
  }, []);

  // Return the user object and auth methods
  return {
    user,
    login,
    logout
  };
}
